function LEAD() {
    // declarations
    var self = this;
    this.product_id = null;
    this.url = null;

    // listeners
    this.listeners = function()
    {
        // on show method called
        $('.lead-action').on('click', function () {
            // resets
            self.resetForm();
            // get product id
            self.product_id = $(this).data('product-id');
            // set product name
            $('#lead .product-container h3').html($(this).data('shop-type-name') + '<br /><strong>' + $(this).data('product-name') + '</strong>');
            // set price
            $('#lead .product-container h4').html($(this).data('price'));
        });

        // next page or final submit button clicked
        $('#lead-submit').on('click', function(e) {
            // prevent default
            e.preventDefault();
            // lock form
            self.lockForm();
            // hide message
            $('#lead-message-container').fadeOut();
            // lock submits
            $(this).prop('disabled', true);
            // declarations
            var success = true;
            var errors = [];
            var formData = {
                'product_id': self.product_id,
                'name': $('#lead-name').val(),
                'phone': $('#lead-phone').val(),
                'email': $('#lead-email').val(),
                'message': $('#lead-message').val(),
                'lead_url': self.url,
                '_token': $("input[name=_token]").val()
            }

            // set ajax url and make ajax call
            var ajaxURL = URL + "/ajax/submit-lead";
            var $_token = $("input[name=_token]").val();
            $.ajax({
                type: "POST",
                data: formData,
                dataType: 'json',
                url: ajaxURL,
                encode: true,
                headers: { 'X-XSRF-TOKEN' : $_token },
                success: function (data) {
                    // remove error class
                    $('#lead-message-container').removeClass('error');
                    // remove error class
                    $('#lead input').removeClass('error');
                    // deactivate overlay screen
                    if (data.success == true) {
                        // hide form container
                        $('#lead .form-container').fadeOut(800);
                        // hide spinner
                        $('#lead .spinner-container').hide();
                        // show message
                        $('#lead-message-container').html(data.message);
                        setTimeout(function() {
                            $('#lead-message-container').fadeIn();
                            $('#lead-message-container').addClass('animated tada');
                        }, 1400);
                    } else {
                        // unlock submits
                        $('#lead-submit').prop('disabled', false);
                        // unlock form
                        self.unlockForm();
                        // unlock submits
                        $('#lead-submit').prop('disabled', false);
                        if (true == data.unexpected_error) {
                            console.log('unx')
                            // show message
                            $('#lead-message-container').html(data.errors);
                            $('#lead-message-container').addClass('error');
                            setTimeout(function() {
                                $('#lead-message-container').fadeIn();
                                $('#lead-message-container').addClass('animated tada');
                            }, 500);
                        } else {
                            // get errors
                            data.errors = $.parseJSON(data.errors);
                            // loop errors
                            $.each(data.errors, function(key, value) {
                                $('#lead-'+key.replace('_', '-')).addClass('error');
                            });
                        }
                    }
                }
            });
        });
    }
    // reset form
    this.resetForm = function() {
        // hide message
        $('#lead-message-container').hide();
        // show form
        $('#lead .form-container').show();
        // hide spinner
        $('#lead .spinner-container').hide();
        // clear form
        $('#lead .form-container input').val('');
        $('#lead .form-container textarea').val('');
        // remove error class
        $('.form-container input').removeClass('error');
        $('.form-container textarea').removeClass('error');
        // unlock submits
        $('#lead-submit').prop('disabled', false);
        // unlock form
        self.unlockForm();
    }
    // lock form
    this.lockForm = function() {
        // disable inputs
        $('.form-container input').prop('disabled', true);
        $('.form-container textarea').prop('disabled', true);
        // load spinner
        $('#lead .spinner-container').fadeIn();
        // remove error class
        $('.form-container input').removeClass('error');
        $('.form-container textarea').removeClass('error');
    }
    // unlock form
    this.unlockForm = function() {
        // enable inputs
        $('.form-container input').prop('disabled', false);
        $('.form-container textarea').prop('disabled', false);
        // remove spinned
        $('#lead .spinner-container').fadeOut();
    }
}
