// common js code
// on page load
 // get scroll data
$(window).scroll(function (event) {
    var scroll = $(window).scrollTop();
    // activate back to top button
    if (scroll > 0) {
        $('#to-top').slideDown(100);
    } else {
        $('#to-top').slideUp(100);
    }
    // activate fixed top header
    if (scroll > 0) {
        $('.header-container').addClass('fixed');
    } else {
        $('.header-container').removeClass('fixed');
    }
});
// to top clicked
$('#to-top').on('click', function() {
    smoothScroll.animateScroll(null, '#top');
});
// search submit
$("#search-submit").on("click", function() {
    $('form#searchForm').submit();
});
