function NEWSLETTER() {
    // declarations
    var self = this;

    // listeners
    this.listeners = function () {
        $('#newsletter-submit').click(function () {
                // get form data
                var formData = {
                    'email': $('#newsletter-email').val(),
                    '_token': $("input[name=_token]").val()
                };
                ajaxInit(formData);

            }
        );

        $('#newsletterForm').submit(function () {
            // get form data
            var formData = {
                'email': $('#newsletter-email').val(),
                '_token': $("input[name=_token]").val()
            }
            ajaxInit(formData);
            return false;
        });

        function ajaxInit(formData) {
            // set ajax url and make ajax call
            var ajaxURL = URL + "/ajax/newsletter-submit";
            var $_token = $("input[name=_token]").val();
            $.ajax({
                type: "POST",
                data: formData,
                dataType: 'json',
                url: ajaxURL,
                encode: true,
                headers: {'X-XSRF-TOKEN': $_token},
                success: function (data) {
                    if (data.success == true) {
                        $('.newsletter-success').show().removeClass('hidden');
                        $('.newsletter-error').html('');
                        $('#newsletter-email').removeClass('has-error').addClass('has-success');
                    }
                    else {
                        $('.newsletter-success-text').hide();
                        $('.newsletter-error').show(200, function () {
                            $('.newsletter-error').html('');
                            $.each(data.errors, function (index, error) {
                                $('.newsletter-error').append('<li>' + error + '</li>');
                                $('#newsletter-email').removeClass('has-success').addClass('has-error');

                            })
                        })
                    }
                },
                error:function(){
                    $('#newsletter-email').addClass('has-error');
                }
            });
        }
    }
}
